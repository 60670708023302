import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BasicService } from '../../common/services/basic.service';
import { API_USER_BENEFICIARY_BATCH_LOAD_URL } from '../../connection.data';

@Injectable()
export class UserBeneficiaryBatchLoadService extends BasicService {
    constructor(http: HttpClient){
        super(http);
        this.baseUrl = API_USER_BENEFICIARY_BATCH_LOAD_URL;
    }

    verifyUserList(userList: string[]):Observable<any>{
        return this.http.post(`${API_USER_BENEFICIARY_BATCH_LOAD_URL}/verify/user-list`, {userList});
    }

    validateBatch(item: any):Observable<any>{
        return this.http.post(`${API_USER_BENEFICIARY_BATCH_LOAD_URL}/validate`, item);
    }
}